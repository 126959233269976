import { NotFoundError, PermissionError } from '@newfront-insurance/data-layer-client';
import { useQuery } from '@newfront-insurance/next-router-provider';
import { useProvider } from '@newfront-insurance/react-provision';
import React from 'react';

import { AuthProvider } from './auth';

interface AccountAccesssProviderProps {
  children: React.ReactNode;
}

/**
 * This provider will check the account data before rendering the pages.
 * If the user doesn't have accounts assigned at all, it will show a message for it.
 * If the user entered an account that is not part of the ones he is allowed to use, it will also show an error message.
 */
export function AccountAccessProvider({ children }: AccountAccesssProviderProps): JSX.Element {
  const { userDetails } = useProvider(AuthProvider);
  const accountUuid = useQuery<string>('accountUuid');

  if (userDetails && userDetails.accounts.length === 0) {
    throw new PermissionError('You do not have access to any accounts');
  }

  if (accountUuid && userDetails && userDetails.accounts.length) {
    const account = userDetails.accounts.find((item) => item.uuid === accountUuid);
    if (!account) {
      throw new NotFoundError('You do not have access to this account');
    }
  }

  return <>{children}</>;
}
