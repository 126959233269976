import { isNotNil } from '@newfront-insurance/core';
import { useProvider } from '@newfront-insurance/react-provision';
import type { Dispatch, SetStateAction } from 'react';
import { useLocalStorage } from 'react-use';

import { AuthProvider } from '../providers/auth';

export function useRecentlyViewedAccount(): [string | undefined, Dispatch<SetStateAction<string | undefined>>] {
  const { userDetails } = useProvider(AuthProvider);
  const userAccounts = userDetails?.accounts.map((account) => account.uuid).filter(isNotNil) ?? [];

  const [recentlyViewedAccount, setRecentlyViewedAccount] = useLocalStorage(
    'recently-viewed-account',
    userDetails?.accounts[0]?.uuid,
  );

  if (recentlyViewedAccount && !userAccounts?.includes(recentlyViewedAccount)) {
    return [userAccounts[0], setRecentlyViewedAccount];
  }

  return [recentlyViewedAccount, setRecentlyViewedAccount];
}
